@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');

//colors:
$footer-color:rgba(184, 184, 184, 0.5)!important;
$primary:darkred!important;
$secondary:white!important;

//fonts:
$font:"Oxygen";

//sidebar:
$sidebar-bg-color: white !default;
$sidebar-color: darkred!default;
$sidebar-width: 200px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: rgb(161, 8, 8) !default;
$submenu-bg-color: white !default;
$submenu-bg-color-collapsed: white !default;
$icon-bg-color: white !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;


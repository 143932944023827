@import "./variables";
@import "./print";
@import "bootstrap/dist/css/bootstrap.min.css";
@import '~react-pro-sidebar/dist/scss/styles.scss';

html {
  position: relative;
  min-height: 100%;
}

#root {
  min-height: 100%;
  font-family: $font!important;
}

.logo {
  height: 80px;
  width: 80px;
}

.logo2 {
  height: 100px;
  width: 100px;
}

.socialicon:hover{
  scale: 1.3;
}

.buttoncustom{
  text-decoration: none;
  background-color: $primary;
  border-color: $primary;
  color:$secondary;

  &:hover{
    background-color: $secondary;
    color: $primary;
    transition: 1s;
  }

  &:focus,
  &:visited {
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
}

.navbarlink{
  font-size: 25px!important;
  text-decoration: none!important;
  color: $primary;
  background-image: linear-gradient(darkred, darkred);
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all .2s ease-out;
}

.navbarlink:hover{
  font-size: 28px!important;
  background-size: 100% 2px, auto;
}

.Home {

  .presentationtext{
    font-size: 20px;
  }

  .importanttextmedium{
    color: $primary;
    font-weight: bold; 
  }

  .captiontext{
    color: $secondary;
    text-shadow: 3px 3px 12px black;
    font-weight: bolder;
    font-style: italic;
    backdrop-filter: blur(15px) saturate(80%)
  }

  .carousel-inner{
    display: flex!important;
    justify-content: center!important;
  }

  .carousel-item{
    margin-right: 0%!important;
    transition: transform .5s linear!important;
  }

  .imagecarousel{
    img{
      width: auto;
      height: 700px;
    }
  }

  .posterimage{
    width: 85%;
    height: 85%;
    margin-left: 2rem;
  }
}

.title{
  font-size: 60px!important;
  color: $primary;
  font-weight: bold;
  margin-bottom: 1rem;
}

.titlecustom{
  font-size: 50px!important;
  color: $primary;
  font-weight: bold;
  margin-bottom: 2rem;
}

.subtitle{
  margin-top: 3rem;
}

.importanttexthome{
  color: $primary;
  font-weight: bolder;
  font-size: 25px;
}

.bigbutton{
  font-size: 25px;
}

.subtitlecustom{
  font-size: 30px!important;
  color: $primary;
}

.JumbotronDisplay{
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.85), 
    rgba(255, 255, 255, 0.1),
    ),
    url(../assets/images/baie-de-morlaix-carantec.webp);
  background-size: cover;
  background-position: center;
  min-height: 600px;
  max-width: 100vw;
  text-align: center;
  font-family: $font!important;

  .jumbotext{
    margin-top: 0%;
  }

  .leftjumbotext{
    margin-top: -1rem;
  }

  .box{
    border: darkred;
    border-radius: 2%;
  }

  .facebook{
    background: white;
    border-color: white;
    border-radius: 50%;
  }

  #expodate{
    font-size: 40px!important;
    font-weight: bolder;
    color: $primary;
  }

  #expoplace{
    font-size: 40px!important;
    font-weight: bolder;
    color: $primary;
  }
}

.Apply{
  max-width: 100vw;
  margin-left: 0!important;
  margin-right: 0!important;

  .inlinelist{
    max-width: 100vw;
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .inlinebutton{
    max-width: 100vw;
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .organisation{
    max-width: 100vw;
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .stepstext{
    font-weight: bolder;
    font-size: large;
  }

  .liststeps{
    list-style: none;
    text-align: left;
  }

  .Applytext{
    margin-left: 0!important;
    margin-right: 0!important;
    ul{
      list-style: none;
    }
  }

  .righttext{
    max-width: 800px!important;
    margin-left: 5%;
  }
  
  .horizontal-list {
    list-style-type: none;
    text-align: center;
    margin-bottom: 1.3rem;   
    padding: 0; //reset
    
    @media (min-width:600px) {
      display: flex;
      justify-content: space-between; 
    }
  }

  .horizontal-list-item {
      width: 100vw;
      position: relative;
      margin-bottom: .5rem;
  
      &:before {
          padding-right: .5rem;
      }
  }

  .emaillink{
    text-decoration: none!important;
    color: $primary;
    background-image: linear-gradient(darkred, darkred);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
  }

  .emaillink:hover{
    background-size: 100% 1px, auto;
  }
  
  .listicon{
    color: $primary;
  }

  .listicon2{
    color: $primary;
    margin-right: 1rem;
  }
  
  .organisation{
    margin-top: 3rem;
  }

  .sidebar-wrapper{
    position: relative;
  }
  
  .Sidebar{
    position: sticky;
    top: 15%;
    margin-left: 2rem;

    .sidetitle{
      font-size: 25px!important;
    }
  }

  .importanttext{
    color: $primary;
    font-weight: bolder;
  }
}

.PdfFile{
  margin-left: 5%;
  margin-right: 5%;
}

.Contacts{
  
  .contactaddress{
    max-width: 100vw;
    margin-top: 4rem;
  }

  .rowbureau{
    max-width: 100vw; 
    margin-top: 4rem;
  }

  .contacttext{
    font-size: 30px;
  }

  .contactbureau{
    font-size: 20px;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  
  .contactlink{
    text-decoration: none!important;
    color: $primary;
    background-image: linear-gradient(darkred, darkred);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
  }
  
  .importanttext{
    color: $primary;
    font-weight: bolder;
    font-size: 35px;
  }
  
  li{
    list-style: none;
  }
}

.Mentionslegales{
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;

  .mentionlink{
    text-decoration: none!important;
    color: $primary;
    background-image: linear-gradient(darkred, darkred);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
  }

  .mentionlink:hover{
    font-size: 18px!important;
    background-size: 100% 1px, auto;
  }
}

footer {
  max-width: 100vw;
  background-color: $footer-color;
  //color:#4b4c4d!important;
  color:black!important;
  padding-top: 7.5vh;
  opacity:0.8;
  font-family: $font!important;

  .footertitle{
    font-weight: bolder;
    color: $primary;
  }

  .footerlink{
    font-size: 15px!important;
    text-decoration: none!important;
    color: $primary;
    background-image: linear-gradient(darkred, darkred);
    background-size: 0 1px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
  }

  .footerlink:hover{
    font-size: 18px!important;
    background-size: 100% 1px, auto;
  }

  .footerlinkmail{
    font-size: 15px!important;
    text-decoration: none!important;
    color: $primary;
    background-image: linear-gradient(darkred, darkred);
    background-size: 0 1px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
  }

  .footerlinkmail:hover{
    background-size: 100% 1px, auto;
  }

  li{
    list-style: none;
  }
}

@media screen and (max-width: 700px) {
  .title{
    font-size: 2.5rem!important;
  }

  .navbarlink {
    font-size: 15px!important;
  }

  .navbarlink:hover{
    font-size: 17px!important;
  }

  #buttonlanguage{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .titlecustom{
    font-size: 2rem!important;
  }
  .buttoncustom{
    margin-top: 1%;
  }

  .JumbotronDisplay{
    #expodate{
      font-size: 2rem!important;
    }
    #expoplace{
      font-size: 2rem!important;
    }
  }

  .box{
    margin-top: 0.5rem!important;
    margin-bottom: 4rem!important;
  }

  .posterimage{
    width: 100%!important;
    height: 100%!important;
    margin-left: 0!important;
    margin-top: 1rem;
  }

  .CarouselDisplay{
    margin-top: 1rem;
  }

  .imagecarousel{
    img{
      width: 100%!important;
      height: auto!important;
    }
  }

  .presentationtext{
    font-size: 12px;
  }

  .Apply{
    .titlecustom{
      font-size: 20px!important;
      margin-top: 2rem;
    }
    .horizontal-list{
      margin-left: 4%!important;
    }

    .horizontal-list-item{
      margin-top: 5%!important;
      margin-left: -10%;
    }

    .stepstext{
      margin-top: 1%!important;
      margin-left: 8%!important;
      margin-right: 8%!important;
      font-size: 12px!important;
    }

    .righttext{
      ul{
        margin-left: -10%;
      }

      #info#artwork#applications#sales#insurances#permanency#others{
        li{
          margin-left: -15%!important;
        }
      }
    }

    .liststeps{
      margin-left: 3%!important;
      margin-right: 3%!important;
    }

    .subtitlecustom{
      font-size: 15px!important;
    }

    li{
      font-size: 12px!important;
    }
  }

  .PdfFile{
    font-size: 12px;
    
    #formtitle{
      font-size: 16px;
    }
  }

  .Contacts{

    .contactaddress{
      margin-left: 0px!important;
      margin-right: 0px!important;
    }

    .contacttext{
      font-size: 15px!important;
      margin-top: 0%;
      margin-left: 3%;
      margin-right: 3%;
    }
  
    .importanttext{
      font-size: 20px!important;
      margin-top: 2rem;
    }

    .rowbureau{
      margin-left: 0px!important;
      margin-right: 0px!important;
    }
  
    .contactbureau{
      font-size: 15px!important;
      max-width: 100%;
      margin-left: 3%!important;
      margin-right: 3%!important;
    }

    h1{
      margin-bottom: 5%!important;
    }

    ul{
      margin-left: 0%!important;
    }

    ol{
      margin-left: -15%!important;
    }

    li{
      font-size: 15px!important;
    }
  }

  .Mentionslegales{

    h2{
      font-size: 17px!important;
      margin-top: 2rem;
    }
    font-size: 15px!important;
  }

  .sidebar-wrapper{
    display: none;
  }

  footer{

    .row{
      margin-left: 5%!important;
      margin-right: 5%!important;
    }

    .footertitle{
      font-weight: bolder;
      color: $primary;
      margin-bottom: 8%;
    }

    .footerlinkresponsive{
      font-size: 1.3rem!important;
    }

    ul{
      margin-left: -10%!important;
    }

    .footernavlink{
      margin-top: 2rem;
    }

    .footersocial{
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (min-width: 2000px) {

  .JumbotronDisplay{
    min-height: 900px;
  }
  
  .leftjumbotext{
    margin-top: 11.5%;
  }

  .box{
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (min-width: 3000px) {
  
  .title{
    font-size: 8rem!important;
  }

  .leftjumbotext{
    margin-top: 8%;
  }
}
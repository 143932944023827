@media print {
    .pdfFile{
        font-size: 14px!important;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;

        .group1{
            display: flex;
            flex-direction: row;
            
            .label{
                width: 10%;
            }

            .textinput{
                width: 40%;
            }

            .textinputlarge{
                width: 90%;
            }
        }
    }
}